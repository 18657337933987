import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from "react-redux";
import FilterCityStoreDiscounts from "../../AppCustomizedComponent/FilterCityStoreDiscounts";
import {hasAValue} from "../../../uitls/SharedFunctions";
import ListItemsStoreDiscounts from "../../AppCustomizedComponent/ListItemsStoreDiscounts";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";
import { getDiscountsCall } from '../../../api/ApiCalls';
import { param } from 'jquery';



export default function StoreDiscountsPart() {
    const appLanguageRS = useSelector(state => state.appLanguageRS);
    let contentLanguage = appLanguageRS === "en"? "en": "nl"


    const initStrings = {
        section1heading: stringsTranslate(appWords.local_discounts),
        section1CounterText: stringsTranslate(appWords.results),
        sectionSearchHeading: stringsTranslate(appWords.results),
        sectionFilterHeading: stringsTranslate(appWords.college_town),
        searchPlaceholder: stringsTranslate(appWords.find_a_local_discount),
        all_categories: stringsTranslate(appWords.all_categories),
        categories: stringsTranslate(appWords.categories),
    }

    let language = appLanguageRS
    const storeDiscountsRS = useSelector(state => state.storeDiscountsRS);

    const [selectedFilterCity, setSelectedFilterCity] = useState(null)
    const [selectedFilterCategory, setSelectedFilterCategory] = useState(null)
    const [searchText, setSearchText] = useState('')
    const [storeDiscounts, setStoreDiscounts] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [resetDiscounts, setResetDiscounts] = useState(true)
    const [totalDiscountsCount, setTotalDiscountsCount] = useState(0)

    useEffect(() => {
        getDiscounts(currentPage);
    }, [currentPage, selectedFilterCategory, selectedFilterCity, searchText])


    const getDiscounts = (page) => {
        
        const params = new URLSearchParams('?');
        
        params.append('page', page)

        if(selectedFilterCategory)
            params.append('categoryId', selectedFilterCategory)
        if(selectedFilterCity)
            params.append('cityId', selectedFilterCity.id)
        
        getDiscountsCall('?' + params)
        .then(res => {
            if(resetDiscounts)
                setStoreDiscounts(res.items)
            else
                setStoreDiscounts((prevDiscounts) => [...prevDiscounts , ...res.items])
            setTotalDiscountsCount(res.count)
        })
    }
    return (
        <section className="section align-items-start justify-content-start mt-5">
            <div className="container-fluid mb-5">
                <div className="row">

                    {/*Element 1*/}
                    <div className="col-lg-2 col-12 text-left border-top border-right d-flex align-items-center">
                        <p className="text-primary">{storeDiscounts.length + " " + initStrings.section1CounterText}</p>
                    </div>

                    {/*Element Search balk*/}
                    <div className="col-lg-10 col-12 text-left border-top d-flex align-items-center">
                        <span className="input-group-text w-100 h-100 border-0 bg-white">
                            <i className="fa fa-search text-primary"/>
                            <input type="text" className="form-control search-input-store-discounts h6 h-100 mt-2"
                                   placeholder={initStrings.searchPlaceholder}
                                   onChange={(val) => { setSearchText(val.target.value); setResetDiscounts(true); setCurrentPage(1); }}/>
                        </span>
                    </div>

                    {/*Element Filter balk*/}
                    <div className="col-lg-2 col-12 text-left border-top border-right border-bottom pb-3 pl-3 pr-3">

                        <FilterCityStoreDiscounts initStrings={initStrings}
                                                  selectedFilterCity={selectedFilterCity}
                                                  setSelectedFilterCity={(val) => setSelectedFilterCity(val)}
                                                  selectedFilterCategory={selectedFilterCategory}
                                                  setSelectedFilterCategory={(val) => setSelectedFilterCategory(val)}
                                                  resetDiscounts={resetDiscounts}
                                                  setResetDiscounts={(val) => setResetDiscounts(val)}
                                                  currentPage={currentPage}
                                                  setCurrentPage={(val) => setCurrentPage(val)}
                                                  />
                    </div>

                    {/*Element List items*/}
                    <ListItemsStoreDiscounts 
                                    totalDiscountsCount={totalDiscountsCount} 
                                    storeDiscounts={storeDiscounts} 
                                    currentPage={currentPage} 
                                    setCurrentPage={(val) => setCurrentPage(val)}
                                    resetDiscounts={resetDiscounts}
                                    setResetDiscounts={(val) => setResetDiscounts(val)}
                                    />
                </div>
            </div>
        </section>
    );
}
