import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import Accordion from "react-bootstrap/Accordion";
import {MdKeyboardArrowUp, MdKeyboardArrowDown} from 'react-icons/md';
import {hasAValue, theNewYearOfKnaek} from "../../uitls/SharedFunctions";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appWords} from "../../config/Constants";
import {IoMdCloseCircle} from "react-icons/io";
import { useHandleLinkClick } from '../../hooks/useHandleLinkClick';
import { getCategoriesCall } from '../../api/ApiCalls';
import { useQuery } from '../../hooks/useQuery';


export default function FilterCityStoreDiscounts({initStrings, selectedFilterCity, setSelectedFilterCity, selectedFilterCategory, setSelectedFilterCategory, resetDiscounts, setResetDiscounts, currentPage, setCurrentPage}) {
    const citiesRS = useSelector(state => state.citiesRS);
    const {city} = useParams();
    const [activeIndex, setActiveIndex] = useState(null);
    const [categories, setCategories] = useState([]);
    const handleLinkClick = useHandleLinkClick();
    const appLanguageRS = useSelector((state) => state.appLanguageRS);
    const params = useQuery();
    const category = params.get("category");

    const handleAccordionClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const filterItems = [
        {
            title: stringsTranslate(appWords.netherlands),
            content: citiesRS.filter(item => item.country_id === 1),
        },
        {
            title: stringsTranslate(appWords.belgium),
            content: citiesRS.filter(item => item.country_id === 2),
        }

    ];

    useEffect(() => {
        getCategories()
    },[]);

    useEffect(() => {
        if (hasAValue(city)) {
           let foundCity = citiesRS.find(item => item.name.toString().toLowerCase().includes(city.toString().toLowerCase()))
           if (hasAValue(foundCity))
               setSelectedFilterCity(foundCity)
       }
       if (hasAValue(category))
            setSelectedFilterCategory(category)

    }, [city, category]);

    useEffect(() => {
        if (hasAValue(selectedFilterCity) || hasAValue(selectedFilterCategory) ) {
            let link = '/studentenkorting' + ( selectedFilterCity?.name ? '/' + selectedFilterCity?.name : '' )   
            if(hasAValue(selectedFilterCategory)) link += '?category=' + selectedFilterCategory
            handleLinkClick(link);
        }

    }, [selectedFilterCity, selectedFilterCategory]);

    const getCategories = () => {
        getCategoriesCall()
        .then(res => {
            setCategories(res)
        })    }
    return (
        <>
            {
                selectedFilterCity && <div className="d-flex py-2 my-2 align-items-center filter-badge-text">
                <a className="badge badge-pill filter-badge-body p-2">
                    <IoMdCloseCircle className="mr-2" onClick={()=> {
                        setSelectedFilterCity(null)
                        setCurrentPage(1)
                        setResetDiscounts(true)
                        //handleLinkClick(appLanguageRS == 'nl' ? '/studentenkorting' : '/student-discounts');
                    }}/>
                    {selectedFilterCity.name}
                </a>
            </div>
            }



                <h4 className="my-3">{ initStrings.categories }</h4>
                <div className="W-100 mb-4 mt-3">
                    <div className="cursor-pointer" >
                        <div className="filter-city-accordion-item-body-md active">
                            
                            <label className={"w-100 my-2 cursor-pointer"} >
                                <input className={"pr-5 mr-1 text-knaek-title check-box-city rounded-circle cursor-pointer "}
                                        type="radio" checked={!selectedFilterCategory}
                                        onClick={() => {
                                            setSelectedFilterCategory(null)
                                            setCurrentPage(1)
                                            setResetDiscounts(true)
                                        }}
                                /> {initStrings.all_categories}
                            </label>
                            {categories.map((item, i) => {
                                let checked = hasAValue(selectedFilterCategory) ? item.id.toString().includes(selectedFilterCategory) : false

                                return (
                                    <label className={"w-100 my-2 cursor-pointer"} key={i}>
                                        <input className={"pr-5 mr-1 text-knaek-title check-box-city rounded-circle cursor-pointer "}
                                               type="radio" checked={checked}
                                               onClick={() => {
                                                    setSelectedFilterCategory(checked ? null : item.id)
                                                    setCurrentPage(1)
                                                    setResetDiscounts(true)
                                                    //handleLinkClick('/studentenkorting' + ( selectedFilterCity?.name ? '/' + selectedFilterCity?.name : '' ) + ( checked ? '?category=' + item.id : '' ));
                                               }}
                                        /> {item.name}
                                    </label>
                                )
                            })}
                        </div>
                    </div>
            </div>

            <h4 className="my-3">{initStrings.sectionFilterHeading}</h4>
            <div className="W-100 ">
                {filterItems.map((item, index) => (
                    <div className="cursor-pointer" key={index}>
                        <div
                            className={"" + (index === activeIndex ? "active  d-flex justify-content-between py-2" : "d-flex justify-content-between  pb-2")}
                            onClick={() => handleAccordionClick(index)}>
                            <h5 className={"filter-city-accordion-item-header"}>{item.title}</h5>
                            {index === activeIndex ?
                                <MdKeyboardArrowUp className={"text-primary h5"}></MdKeyboardArrowUp> :
                                <MdKeyboardArrowDown className={"text-primary h5"}></MdKeyboardArrowDown>
                            }

                        </div>
                        <div
                            className={"filter-city-accordion-item-body-md " + (index === activeIndex ? "active" : "")}>
                            {item.content.map((item, i) => {
                                let checked = hasAValue(selectedFilterCity) ? item.name.toString().includes(selectedFilterCity.name) : false

                                return (
                                    <label className={"w-100 my-2 cursor-pointer"} key={i}>
                                        <input className={"pr-5 mr-1 text-knaek-title check-box-city rounded-circle cursor-pointer "}
                                               type="radio" checked={checked}
                                               onClick={() => {
                                                   setSelectedFilterCity(checked ? null : item)
                                                   //handleLinkClick('/studentenkorting/' + item.name)
                                                   setActiveIndex(null)
                                                   setCurrentPage(1)
                                                   setResetDiscounts(true)
                                               }}
                                        /> {item.name}
                                    </label>
                                )
                            })}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
